import { BackArrow, Input, Tabs, Header } from '../components';
import { FC, useState } from 'react';

interface iJoinProps {
  visible: boolean;
  onBack: () => void;
}
const Join: FC<iJoinProps> = ({ visible, onBack }) => {
  const [method, setMethod] = useState<string>('PICK_UP');
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  return (
    <div className={`overlay join-wrapper ${visible ? 'visible' : 'hidden'}`}>
      <Header label='Personal data' backLabel='Clubs' onBack={onBack} />
      <main>
        <Tabs
          value={method}
          onSelect={setMethod}
          label='Select delivery method'
          tabs={[
            { label: 'Pick Up Wine', value: 'PICK_UP' },
            { label: 'Ship wine', value: 'SHIPPING' },
          ]}
        />
        {method === 'PICK_UP' ? (
          <>
            <Input value={name} onChange={setName} label='Full name' placeholder='John Doe' />
            <Input value={email} onChange={setEmail} label='Email' placeholder='johndoe@example.com' />
            <Input value={password} onChange={setPassword} label='Password' placeholder='****' type='password' />
          </>
        ) : (
          <>
            <Input value={name} onChange={setName} label='Full name' placeholder='John Doe' />
            <Input value={email} onChange={setEmail} label='Email' placeholder='johndoe@example.com' />
            <Input value={password} onChange={setPassword} label='Password' placeholder='****' type='password' />
            <Input
              value={address}
              onChange={setAddress}
              label='Address'
              placeholder='London, SE6 1AA, Brownhill road 266'
            />
          </>
        )}
      </main>
    </div>
  );
};

export default Join;
