import { FC, useEffect, useState } from 'react';
import BackArrow from './BackArrow';

interface iHeaderProps {
  label: string;
  backLabel: string;
  onBack: () => void;
}
const Header: FC<iHeaderProps> = ({ onBack, label, backLabel }) => {
  return (
    <header>
      <div className='back' onClick={onBack}>
        <BackArrow />
        <span>{backLabel}</span>
      </div>
      <h1>{label}</h1>
    </header>
  );
};

export default Header;
