import { BackArrow, Input, Header } from '../components';
import { FC, useState } from 'react';

interface iJoinProps {
  visible: boolean;
  onBack: () => void;
}
const Join: FC<iJoinProps> = ({ visible, onBack }) => {
  const [name, setName] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [cvc, setCVC] = useState<string>('');
  const [date, setDate] = useState<string>('');
  return (
    <div className={`overlay payment-wrapper ${visible ? 'visible' : 'hidden'}`}>
      <Header label='Payment info' backLabel='Personal data' onBack={onBack} />
      <main>
        <Input value={name} onChange={setName} label='Cardholders name' placeholder='John Doe' />
        <Input value={number} onChange={setNumber} label='Card number' placeholder='0000-0000-0000-0000' />
        <div className='row'>
          <Input value={cvc} onChange={setCVC} label='CVC' placeholder='***' type='password' />
          <Input value={date} onChange={setDate} label='Date' placeholder='01/22' />
        </div>
      </main>
    </div>
  );
};

export default Join;
