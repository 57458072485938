import { FC, useRef } from 'react';

interface iInputProps {
  label: string;
  placeholder: string;
  value: string;
  onChange: (val: string) => void;
  type?: string;
}
const Input: FC<iInputProps> = ({ label, placeholder, type, value, onChange }) => {
  const _handleChange = (e: any) => onChange(e.target.value);
  const id = useRef<string>(`input-${Math.random()}`);
  return (
    <div className='input-wrapper'>
      <label htmlFor={id.current}>{label}</label>
      <input onChange={_handleChange} value={value} type={type || 'text'} id={id.current} placeholder={placeholder} />
    </div>
  );
};

export default Input;
