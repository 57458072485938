import { BackArrow, Input, Header } from '../components';
import { FC, useState, useRef, useEffect } from 'react';

interface iJoinProps {
  visible: boolean;
  onBack: () => void;
}
const Join: FC<iJoinProps> = ({ visible, onBack }) => {
  const [method, setMethod] = useState<string>('PICK_UP');
  const wrapper = useRef<HTMLDivElement>(null);

  useEffect(() => {
    for (let i = 0; i < 100; i++) {
      // Random rotation
      var randomRotation = Math.floor(Math.random() * 360);
      // Random Scale
      var randomScale = Math.random() * 1;
      // Random width & height between 0 and viewport
      var randomWidth = Math.floor(
        Math.random() * Math.max(document.documentElement.clientWidth, window.innerWidth || 0),
      );
      var randomHeight = Math.floor(
        Math.random() * Math.max(document.documentElement.clientHeight, window.innerHeight || 500),
      );

      // Random animation-delay
      var randomAnimationDelay = Math.floor(Math.random() * 15);

      // Random colors
      var colors = ['#0CD977', '#FF1C1C', '#FF93DE', '#5767ED', '#FFC61C', '#8497B0'];
      var randomColor = colors[Math.floor(Math.random() * colors.length)];

      // Create confetti piece
      var confetti = document.createElement('div');
      confetti.className = 'confetti';
      confetti.style.top = randomHeight + 'px';
      confetti.style.left = randomWidth + 'px';
      confetti.style.backgroundColor = randomColor;
      // confetti.style.transform='scale(' + randomScale + ')';
      confetti.style.opacity = '0';
      //String(randomScale);
      confetti.style.transform = 'skew(15deg) rotate(' + randomRotation + 'deg)';
      confetti.style.animationDelay = randomAnimationDelay + 's';
      wrapper.current?.appendChild(confetti);
    }
  }, []);
  return (
    <div ref={wrapper} className={`overlay welcome-wrapper payment-wrapper ${visible ? 'visible' : 'hidden'}`}>
      <Header label='Welcome to the Club!' backLabel='Clubs' onBack={onBack} />
      <main>
        <p>You will receive confirmation soon!</p>
        <button onClick={onBack}>Done</button>
      </main>
    </div>
  );
};

export default Join;
