import { FC, useState, useEffect } from 'react';
import { Skeleton } from '../layout';
import { Footer } from '../components';

import Clubs from './Clubs';
import Join from './Join';
import Payment from './Payment';
import Welcome from './Welcome';

const clubs = [
  {
    banner: 'four.png',
    title: 'Four botte club',
    price: '$300 - $400 / shipment',
    description:
      'Ships every other month,  Eight bottles of wine (each unique), 10% discount on site, 10% discount on wine',
  },
  {
    banner: 'eight.png',
    title: 'Eight botte club',
    price: '$500 - $600 / shipment',
    description:
      'Ships every other month,  Eight bottles of wine (each unique), 10% discount on site, 10% discount on wine',
  },
  {
    banner: 'twelve.png',
    title: 'Twelve botte club',
    price: '$600 - $700 / shipment',
    description:
      'Ships every other month,  Eight bottles of wine (each unique), 10% discount on site, 10% discount on wine',
  },
];

const Navigation: FC = () => {
  const [club, setClub] = useState<any>(0);
  const [state, setState] = useState<'CLUBS' | 'JOIN' | 'PAYMENT' | 'SUCCESS'>('CLUBS');

  const _handleNext = () => {
    if (state === 'CLUBS') setState('JOIN');
    else if (state === 'JOIN') setState('PAYMENT');
    else if (state === 'PAYMENT') setState('SUCCESS');
  };
  const _handleBack = () => {
    if (state === 'JOIN') setState('CLUBS');
    else if (state === 'PAYMENT') setState('JOIN');
    else if (state === 'SUCCESS') setState('CLUBS');
  };

  useEffect(() => {
    if (state === 'CLUBS') {
      document.body.style.overflow = 'auto';
    } else document.body.style.overflow = 'hidden';
  }, [state]);

  const _handleSelect = (club: any) => {
    setClub(club);
    _handleNext();
  };

  return (
    <Skeleton>
      <div className='content'>
        <Clubs onClubSelect={_handleSelect} clubs={clubs} />
        <Join onBack={_handleBack} visible={state !== 'CLUBS'} />
        <Payment onBack={_handleBack} visible={state === 'PAYMENT'} />
        <Welcome onBack={_handleBack} visible={state === 'SUCCESS'} />
      </div>
      <Footer
        hidden={['SUCCESS', 'CLUBS'].includes(state)}
        onSubmit={_handleNext}
        title={club.title}
        subtitle={club.price}
        actionLabel={state === 'PAYMENT' ? 'Pay' : state === 'CLUBS' ? 'Join' : 'Next'}
      />
    </Skeleton>
  );
};

export default Navigation;
