import { Club } from '../components';
import { FC, useRef, createRef, useEffect, useState, memo } from 'react';

interface iClubsProps {
  clubs: any[];
  onClubSelect: (club: any) => void;
}

const Clubs: FC<iClubsProps> = ({ onClubSelect, clubs }) => {
  const refs = clubs.map((club) => createRef<HTMLDivElement>());
  const wrapper = useRef<HTMLDivElement>(null);

  const [scrolled, setScrolled] = useState(false);
  const _handleScroll = () => setScrolled(window.scrollY > 30);
  useEffect(() => {
    window.addEventListener('scroll', _handleScroll);
    return () => window.removeEventListener('scroll', _handleScroll);
  });

  // const getDistance = (el: HTMLDivElement | null) => {
  //   if (!el) return Infinity;
  //   const { top, height } = el.getBoundingClientRect();
  //   return top; // + height / 2 - (wrapper.current?.scrollHeight || 0);
  // };
  // const getVisible = (elements: number[]) => {
  //   if (elements[elements.length - 1] < 0) return elements.length - 1;
  //   let index = 0;
  //   let value = Infinity;
  //   for (let i = 0; i < elements.length; i++) {
  //     if (elements[i] < value && elements[i] >= 0) {
  //       value = Number(elements[i]);
  //       index = i;
  //     }
  //   }
  //   return index;
  // };
  // const _handleScroll = () => {
  //   const elements = refs.map(({ current }) => getDistance(current));
  //   setActiveIndex(getVisible(elements));
  // };

  // useEffect(() => {
  //   window?.addEventListener('scroll', _handleScroll);
  //   return () => window?.removeEventListener('scroll', _handleScroll);
  // });

  return (
    <div className='clubs-wrapper' ref={wrapper}>
      <h1 className={scrolled ? 'scrolled' : ''}>Pick a membership</h1>
      {clubs.map((club, index) => (
        <Club clubRef={refs[index]} {...club} key={club.title} onSelect={() => onClubSelect(club)} />
      ))}
    </div>
  );
};

export default memo(Clubs);
