import { FC } from 'react';

interface iValue {
  label: string;
  value: string;
}
interface iTabProps {
  label: string;
  tabs: iValue[];
  value: string;
  onSelect: (val: string) => void;
  type?: string;
}

const Tabs: FC<iTabProps> = ({ label, tabs, value, onSelect }) => {
  const _handleSelect = (tab: string) => () => onSelect(tab);
  return (
    <div className='input-wrapper'>
      <label>{label}</label>
      <div className='tabs-wrapper'>
        {tabs.map((tab) => (
          <div
            onClick={_handleSelect(tab.value)}
            className={`tab-wrapper ${value === tab.value ? 'active' : ''}`}
            key={tab.value}
          >
            {tab.label}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
