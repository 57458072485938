import { FC } from 'react';

interface iClubProps {
  title: string;
  banner: string;
  price: string;
  description: string;
  clubRef: any;
  onSelect?: () => void;
}

const Club: FC<iClubProps> = ({ title, banner, description, price, clubRef, onSelect }) => {
  return (
    <div className='club-wrapper' ref={clubRef} onClick={onSelect}>
      <div className='banner'>
        <img src={banner} alt='' />
      </div>
      <div className='header'>
        <span className='title'>{title}</span>
        <button>Join</button>
      </div>
      <p>{price}</p>
      <p>{description}</p>
    </div>
  );
};

export default Club;
