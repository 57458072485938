import { FC } from 'react';

interface iFooterProps {
  title: string;
  subtitle: string;
  actionLabel: string;
  onSubmit: () => void;
  hidden?: boolean;
}
const Footer: FC<iFooterProps> = ({ title, subtitle, actionLabel, onSubmit, hidden }) => {
  return (
    <footer className={hidden ? 'hidden' : ''}>
      <div className='content'>
        <span className='title'>{title}</span>
        <span className='subtitle'>{subtitle}</span>
      </div>
      <button onClick={onSubmit}>{actionLabel}</button>
    </footer>
  );
};

export default Footer;
